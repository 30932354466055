<template>
    <div>
        <div class="flex justify-between p-3 mb-3 border-b border-gray-200">
            <div class="flex">
                <ClientsSearch @on-selected="onClientChange" :selected="preSelectedClient" />
            </div>
            <div class="flex">
                <el-checkbox label="Visa raderade" v-model="showDeleted" @change="onShowDeletedChange" />
                <el-checkbox label="Visa test" v-model="showTest" @change="onShowTestChange" />
            </div>
            <div class="flex">
                <el-date-picker
                    v-if="workMode === 'advanced'"
                    v-model="dateRange"
                    class="mr-3"
                    @change="datePickerChange"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="Startdatum"
                    end-placeholder="Slutdatum"
                />
                <SearchBar @change="searchInputChange" />
            </div>
        </div>
    </div>
</template>
<script>
import queryString from "query-string";
import moment from "moment";

export default {
    data() {
        return {
            workMode: "advanced",
            invoicesType: null,
            dateRange: [],
            preSelectedClient: {},
            showDeleted: false,
            showTest: false,
        };
    },

    components: {
        ClientsSearch: () => import("@/components/clientsSearch.vue"),
        SearchBar: () => import("./SearchBar.vue"),
    },

    created() {
        this.checkPreselectedClient();
    },

    methods: {
        checkPreselectedClient() {
            const { clientId } = queryString.parse(window.location.search, { sort: false });
            this.preSelectedClient = {
                value: clientId,
            };
        },

        async onClientChange(client) {
            const query = queryString.parse(window.location.search, { sort: false });
            query.clientId = client.value;
            this.$router.replace({ query }).catch(() => {});
            this.searchParametersChanged();
        },

        onShowTestChange() {
            const query = queryString.parse(window.location.search, { sort: false });
            query.showTest = this.showTest;
            this.$router.replace({ query }).catch(() => {});
            this.searchParametersChanged();
        },

        onShowDeletedChange() {
            const query = queryString.parse(window.location.search, { sort: false });
            query.showDeleted = this.showDeleted;
            this.$router.replace({ query }).catch(() => {});
            this.searchParametersChanged();
        },

        datePickerChange() {
            const query = queryString.parse(window.location.search, { sort: false });
            query.startDate = this.dateRange ? moment(this.dateRange[0]).format("YYYY-MM-DD") : "";
            query.endDate = this.dateRange ? moment(this.dateRange[1]).format("YYYY-MM-DD") : "";
            this.$router.replace({ query }).catch(() => {});
            this.$emit("searchParametersChanged");
        },

        searchInputChange() {
            this.$emit("searchInputChange");
        },

        searchParametersChanged() {
            this.$emit("searchParametersChanged");
        },
    },
};
</script>
