<template>
    <div>
        <div class="flex justify-between p-3 mb-3 border-b border-gray-200">
            <div class="flex">
                <ClientsSearch @on-selected="onClientChange" :selected="preSelectedClient" />
                <el-autocomplete class="ml-4" v-model="category" :fetch-suggestions="querySearch" placeholder="Sök kategori..." :trigger-on-focus="false" @select="handleSelect" />
            </div>

            <div class="flex">
                <el-checkbox style="margin-top: 5px; margin-right: 15px;" label="Visa test" v-model="test" @change="showTestChanged" />

                <el-date-picker
                    v-if="workMode === 'advanced'"
                    v-model="dateRange"
                    class="mr-3"
                    @change="datePickerChange"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="Startdatum"
                    end-placeholder="Slutdatum"
                />
                <SearchBar @change="searchInputChange" />
            </div>
        </div>
        <StatusSelect ref="statusSelect" v-if="workMode === 'advanced'" @searchParametersChanged="searchParametersChanged" />
    </div>
</template>
<script>
import queryString from "query-string";
import moment from "moment";
import Api from "../receipts.api";

export default {
    data() {
        return {
            workMode: "advanced",
            invoicesType: null,
            dateRange: [],
            preSelectedClient: {},
            test: false,
            category: "",
            categorySuggestions: [],
        };
    },

    components: {
        ClientsSearch: () => import("@/components/clientsSearch.vue"),
        SearchBar: () => import("./SearchBar.vue"),
        StatusSelect: () => import("./StatusSelect.vue"),
    },

    async created() {
        const { dateFrom, dateTo } = queryString.parse(window.location.search, { sort: false });
        if (dateFrom !== null && dateTo !== null) {
            this.dateRange = [dateFrom, dateTo];
        }
        this.checkPreselectedClient();
        this.categorySuggestions = await Api.getAllCategoryNames();
    },

    methods: {
        querySearch(queryString, cb) {
            var links = this.categorySuggestions;
            var results = queryString ? links.filter(this.createFilter(queryString)).map(item => ({ value: item })) : links.map(item => ({ value: item }));
            cb(results);
        },
        createFilter(queryString) {
            return link => {
                return link.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
            };
        },
        checkPreselectedClient() {
            const { clientId } = queryString.parse(window.location.search, { sort: false });
            this.preSelectedClient = {
                value: clientId,
            };
        },
        handleSelect(item) {
            console.log(item);
            const query = queryString.parse(window.location.search, { sort: false });
            query.category = item.value;
            this.$router.replace({ query }).catch(() => {});
            this.searchParametersChanged();
        },
        async onClientChange(client) {
            const query = queryString.parse(window.location.search, { sort: false });
            query.clientId = client.value;
            this.$router.replace({ query }).catch(() => {});
            this.searchParametersChanged();
        },

        showTestChanged() {
            const query = queryString.parse(window.location.search, { sort: false });
            query.test = this.test;
            this.$router.replace({ query }).catch(() => {});
            this.$emit("searchParametersChanged");
            setTimeout(() => {
                this.$refs.statusSelect.getAll();
            }, 100);
        },

        datePickerChange() {
            const query = queryString.parse(window.location.search, { sort: false });
            query.dateFrom = this.dateRange ? moment(this.dateRange[0]).format("YYYY-MM-DD") : "";
            query.dateTo = this.dateRange ? moment(this.dateRange[1]).format("YYYY-MM-DD") : "";
            this.$router.replace({ query }).catch(() => {});
            this.$emit("searchParametersChanged");

            this.$refs.statusSelect.getAll();
        },

        searchInputChange() {
            this.$emit("searchInputChange");
            this.$refs.statusSelect.getAll();
        },

        searchParametersChanged() {
            this.$emit("searchParametersChanged");
            setTimeout(() => {
                this.$refs.statusSelect.getAll();
            }, 100);
        },

        currencyChanged() {
            const query = queryString.parse(window.location.search, { sort: false });
            query.currency = this.currency;
            this.$router.replace({ query }).catch(() => {});
            this.$emit("searchParametersChanged");
        },
    },
};
</script>
